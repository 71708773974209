.privacy-policy {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    margin: 20px;
  }
  
  .privacy-policy h1 {
    text-align: center;
    font-weight: 800;
    color: #000000;
  }
  
  .privacy-policy h2 {
    color: #000000;
    font-weight: 600;
    margin-top: 20px;
  }
  
  .privacy-policy h3 {
    color: #5d6d7e;
    margin-top: 15px;
  }
  
  .privacy-policy p {
    margin: 10px 0;
  }
  
  .privacy-policy ul {
    list-style-type: disc;
    margin-left: 20px;
  }
  
  .privacy-policy a {
    color: #3498db;
    text-decoration: none;
  }
  
  .privacy-policy a:hover {
    text-decoration: underline;
  }
  
  .privacy-policy address {
    font-style: normal;
    margin-top: 10px;
  }
  